"use strict";

angular.module("informaApp")
    .service("BarChartHelper", ["d3", function (d3) {
        var result = {};

        result.Chart = function (canvasElement) {
            var elementIds = {
                mask: "mask",
                chartGroups: {
                    pinned: "pinned",
                    scrollable: "scrollable"
                },
                chartyStation: "charty-station",
                dropShadow: "drop-shadow",
                pinnedBackground: "pinned-background"
            };

            var config =  function () {
                var margin = {top: 30, right: 0, bottom: 190, left: 60};

                return {
                    margin: margin,
                    height: 472 - margin.top - margin.bottom,
                    width: 1100 - margin.left - margin.right,
                    initialWidth: 150,

                    columnWidth: 12,
                    columnSpacing: 14,
                    groupPadding: 16,
                };
            }();

            var htmlTemplates = {
                tip: function (d) {
                    return "<div><p class='caption selected'>" + d.label.replace(/\//g, "/ ") + "</p>" +
                        "<p class='caption'>Phase: " + d.phase + "</p>" +
                        "<p class='caption'>PoS: " + d.PTS + "</p>" +
                        "<p class='caption'>LoA: " + d.LOA + "</p>" +
                        "<p class='caption'>Duration of adv. programs: " + d.Duration + "</p>" +
                        "<p class='caption'>Nº of transitions: " + d.Total + "</p></div>";
                }
            };

            var svg, rectMask, pinned, scrollable,chart,tip,pinnedSet,scrollableSet,maxGroups,groupWidth,scrollableInitX;

            tip = createTip();

            svg = createInitialSvg();
            var defs = svg.append("defs");

            rectMask = createMask(defs);
            var filter = createShadow(defs);

            svg.call(tip);

            chart = createChart();
            pinned = createPinnedGroupFromChart();
            scrollable = createScrollableGroupFromChart();

            addBackgroundToPinnedGroup(pinned);
            addTransformToGroup(scrollable, "scrollable");
            addTransformToGroup(pinned, "pinned");

            chart.append("g").attr("class", "y axis");

            function createChart() {
                return svg.append("g")
                    .attr("transform", "translate(" + config.margin.left + ", " + config.margin.top + ")")
                    .attr("width", config.initialWidth);
            }

            function createPinnedGroupFromChart() {
                var pinned = chart.append("g")
                    .attr("id", elementIds.chartGroups.pinned);

                return pinned;
            }

            function createScrollableGroupFromChart() {
                var scrollableContainer = chart.append("g")
                    .attr("clip-path", "url(#" + elementIds.mask + ")");

                return scrollableContainer.append("g")
                    .attr("id", elementIds.chartGroups.scrollable);
            }

            function addBackgroundToPinnedGroup(pinned) {
                pinned.append("rect")
                    .attr("id", elementIds.pinnedBackground)
                    .attr("transform", "translate(0, " + (-config.margin.top + 10) + ")")
                    .attr("height", (config.height + config.margin.top + config.margin.bottom - 20))
                    .style("filter", "url(#" + elementIds.dropShadow + ")");
            }

            function addTransformToGroup(group, className) {
                group.append("g")
                    .attr("transform", "translate(0, " + config.height + ")")
                    .attr("class", "x axis " + className);
            }

            function createTip() {
                var offsetXY = [-10, 0];

                var tip = d3.tip()
                    .attr("class", "d3-tip bar-chart-tooltip")
                    .offset(offsetXY)
                    .html(htmlTemplates.tip);

                return tip;
            }

            function createInitialSvg() {
                var svg = d3.select(canvasElement)
                    .append("svg")
                    .classed("chart", true)
                    .attr("id", elementIds.chartyStation);

                svg.append("text")
                    .classed("caption", true)
                    .attr("x", config.margin.left - 3)
                    .attr("y", config.height + config.margin.top + 27)
                    .attr("text-anchor", "end")
                    .text("Number of")
                    .append("tspan")
                    .attr("x", config.margin.left - 3)
                    .attr("dy", 14)
                    .text("programs");

                return svg;
            }

            function createMask(defs) {
                var mask = defs.append("clipPath")
                    .attr("id", elementIds.mask);

                return mask.append("rect");
            }

            function createShadow(defs) {
                var filter = defs.append("filter")
                    .attr("id", elementIds.dropShadow)
                    .attr("height", "130%");

                filter.append("feGaussianBlur")
                    .attr("in", "SourceAlpha")
                    .attr("stdDeviation", 2)
                    .attr("result", "blur");

                filter.append("feOffset")
                    .attr("in", "blur")
                    .attr("dy", 2)
                    .attr("result", "offsetBlur");

                var feComponentTransfer = filter.append("feComponentTransfer")
                    .attr("in", "offsetBlur")
                    .attr("result", "opacity");

                feComponentTransfer.append("feFuncA")
                    .attr("type", "linear")
                    .attr("slope", 0.2);

                var feMerge = filter.append("feMerge");

                feMerge.append("feMergeNode")
                    .attr("in", "opacity");

                feMerge.append("feMergeNode")
                    .attr("in", "SourceGraphic");

                return filter;
            }

            this.update = function (activePhases, pinnedSet_, scrollableSet_, maxYScale, postfixY, metric, callback) {
                pinnedSet = pinnedSet_;
                scrollableSet = scrollableSet_;

                config.width = _.min([canvasElement.clientWidth || config.width, config.width]);

                svg.attr("width", config.width + config.margin.left + config.margin.right)
                    .attr("height", config.height + config.margin.top + config.margin.bottom);

                groupWidth = activePhases.length * config.columnWidth + (activePhases.length - 1) * config.columnSpacing + 2 * config.groupPadding;
                maxGroups = Math.floor(config.width / groupWidth);
                scrollableInitX = pinnedSet_.length * groupWidth;
                var scrollableWidth = scrollableSet_.length * groupWidth;

                rectMask
                    .attr("width", (maxGroups - pinnedSet_.length) * groupWidth)
                    .attr("height", config.height + config.margin.top + config.margin.bottom)
                    .attr("x", scrollableInitX)
                    .attr("y", -config.margin.top);

                scrollable.attr("transform", "translate(" + scrollableInitX + ", 0)");

                var formatValue = (function (d) {
                    return d + postfixY
                });

                var yScale = d3.scale.linear()
                    .domain([0, maxYScale])
                    .range([config.height, 0]);

                var yAxis = d3.svg.axis()
                    .scale(yScale)
                    .orient("left")
                    .tickFormat(formatValue);

                chart.select(".y.axis")
                    .transition()
                    .duration(2000)
                    .call(yAxis);

                d3.select("#" + elementIds.pinnedBackground)
                    .attr("width", groupWidth * pinnedSet_.length);

                drawBars(activePhases, scrollableSet_, scrollable, metric, yScale);
                if (pinnedSet_.length > 0) {
                    drawBars(activePhases, pinnedSet_, pinned, metric, yScale);
                }

                if (callback) {
                    callback();
                }
            };

            function wrap(text, width) {
                text.each(function () {
                    var text = d3.select(this);
                    // var text = originalText.replace(/\//g, " ");
                    var textString = text.text().replace(/\//g, "/ ");

                    var words = textString.split(/[\s,]+/).reverse(),
                        word,
                        line = [],
                        lineNumber = 0,
                        lineHeight = 1.1, // ems
                        y = text.attr("y"),
                        dx = parseFloat(text.attr("dx")),
                        dy = parseFloat(text.attr("dy")),
                        tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "px");

                    while (word = words.pop()) {
                        line.push(word);
                        tspan.text(line.join(" "));
                        if (tspan.node().getComputedTextLength() > width) {
                            line.pop();
                            tspan.text(line.join(" "));
                            line = [word];
                            tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dx", dx + "px").attr("dy", ++lineNumber * lineHeight + "em").text(word);
                        }
                    }
                });
            }

            function drawBars(phases, dataset, container, metric, yScale) {
                var width = groupWidth * dataset.length;

                var xScale = d3.scale.ordinal()
                    .rangeRoundBands([0, width])
                    .domain(dataset.map(function (obj) {
                        return obj.label;
                    }));

                var xAxis = d3.svg.axis()
                    .scale(xScale)
                    .orient("bottom");

                chart.select(".x.axis." + (container.attr("id")))
                    .call(xAxis)
                    .selectAll("text")
                    .style("text-anchor", "end")
                    .attr("transform", function (d) {
                        return "rotate(-90)"
                    })
                    .attr("dx", "-55px")
                    .attr("dy", "-6px")
                    .call(wrap, 130);

                var groups = container.selectAll("g.group")
                    .data(dataset);

                var groupsEnter = groups.enter()
                    .append("g")
                    .classed("group", true);

                var groupsUpdate = groups
                    .attr("transform", function (d, i) {
                        return "translate(" + i * groupWidth + ", 0)";
                    });

                var groupsExit = groups.exit()
                    .remove();

                var bars = groups.selectAll("rect")
                    .data(function (d, i) {
                        return d[metric];
                    });

                var barsEnter = bars.enter()
                    .append("rect")
                    .classed("bar", true)
                    .each(function (d, i) {
                        var phase = phases[i];
                        d3.select(this).classed(phase, true)
                    });

                var barsUpdate = bars
                    .transition()
                    .duration(2000)
                    .attr("x", function (d, i) {
                        return config.groupPadding + i * (config.columnWidth + config.columnSpacing);
                    })
                    .attr("y", function (d, i) {
                        return yScale(d);
                    })
                    .attr("width", config.columnWidth)
                    .attr("height", function (d) {
                        return config.height - yScale(d);
                    })
                    .attr("class", function (d, i) {
                        return phases[i];
                    });

                var barsInteraction = bars
                    .on("mouseover", function (d, i) {
                        var dataFromGroup = d3.select(this.parentNode).datum();
                        var dataFromCurrPhase = {
                            label: dataFromGroup["label"],
                            phase: phases[i].toUpperCase(),
                            PTS: dataFromGroup["PTS"][i] + "%",
                            LOA: dataFromGroup["LOA"][i] + "%",
                            Duration: dataFromGroup["Duration"][i],
                            Total: dataFromGroup["NumberOfPrograms"][i]
                        };

                        tip.show(dataFromCurrPhase);
                    })
                    .on("mouseout", function (d, i) {
                        tip.hide();
                    });

                var barsExit = bars.exit().remove();

                var numbers = groups.selectAll(".number")
                    .data(function (d, i) {
                        return d[metric];
                    });

                var numbersEnter = numbers.enter()
                    .append("text")
                    .classed("number", true)
                    .style("text-anchor", "middle");

                var numbersUpdate = numbers
                    .transition()
                    .duration(2000)
                    .attr("x", function (d, i) {
                        return config.groupPadding + i * (config.columnWidth + config.columnSpacing) + config.columnWidth / 2;
                    })
                    .attr("y", function (d, i) {
                        return yScale(d) - 5;
                    })
                    .text(function (d, i) {
                        return d;
                    });

                var numbersExit = numbers.exit().remove();

                var numberOfPrograms = container.selectAll(".number-of-programs").data(dataset);

                var numberOfProgramsEnter = numberOfPrograms.enter()
                    .append("text")
                    .style("text-anchor", "middle")
                    .classed("number-of-programs", true);

                var numberOfProgramsUpdate = numberOfPrograms
                    .transition()
                    .duration(2000)
                    .attr("x", function (d, i) {
                        return xScale(d["label"]) + groupWidth / 2;
                    })
                    .attr("y", function (d, i) {
                        return config.height + 35;
                    })
                    .text(function (d, i) {
                        return d["uniqueDrugIndicationQuantity"];
                    });

                var numberOfProgramsExit = numberOfPrograms.exit().remove();

                var linesPosition = [config.height + 15, config.height + 45];

                var lines = container.selectAll(".divider").data(linesPosition);

                var linesEnter = lines
                    .enter()
                    .append("line")
                    .classed("divider", true)
                    .attr("x1", 10)
                    .attr("y1", function (d, i) {
                        return d;
                    })
                    .attr("y2", function (d, i) {
                        return d;
                    });

                var linesTransition = lines
                    .transition()
                    .duration(2000)
                    .attr("x2", width);
            };

            this.scroll = function (direction) {
                var increment = (direction === "forward")
                    ? (-(maxGroups - pinnedSet.length) * groupWidth)
                    : ((maxGroups - pinnedSet.length) * groupWidth);

                var currX = d3.transform(scrollable.attr("transform")).translate[0];
                var newX = currX + increment;
                var scrollableWidth = scrollableSet.length * groupWidth;

                if (scrollableInitX >= newX && newX > -scrollableWidth + scrollableInitX) {
                    scrollable
                        .transition()
                        .duration(1000)
                        .attr("transform", function () {
                            return "translate(" + (currX + increment) + ", 0)";
                        });
                }
            }

            this.scrollForward = () => {
                this.scroll('forward');
            };

            this.scrollBackward = () => {
                this.scroll();
            };

            this.showHideNumbers = function (show) {
                svg.selectAll(".number").style("display", (show) ? ("block") : ("none"));
            };

            this.setXAxisVisibility = (isVisible) => {
                svg.selectAll('.x.axis').style('display', isVisible ? 'block' : 'none');
            };

            this.destroy = () => {
                d3.selectAll('.d3-tip.bar-chart-tooltip').remove();
            }
        };

        return result;
    }]);